/* eslint-disable space-before-function-paren */
import { setToken } from '@/common/auth'
import Vue from 'vue'
import { deepClone } from '../../common/util/index'
// import { localCache } from '@/common/storage'
// import { UPDATE_TOKEN } from '../../config/index'
// import { router } from '../../routes/router'
import * as types from '../action-type'
// import { FILE } from '../action-type'
import { baseURL, newApi, portalURL, api } from '@/config'
// import { GET_SIGN_AUTH } from '../action-type'
// import { GET_MEETING_REGISTER_CONFIGURATION } from '../action-type'
// import { CENTER, GET_AUTH_TOKEN } from '../action-type'
// import { GET_DESK_NOTES } from '../action-type'
// import { GET_HOTEL_DETAIL } from '../action-type'

const actions = {
  [types.GET_MEETING]({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      Vue.req
        .get(`/meeting/approval/${id}`, {
          baseURL: baseURL()
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  [types.Get_MEETING_SUBPAGE]({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      Vue.req
        .get(`/meeting/${data.meetingId}/subpage/${data.pageId}`, {
          baseURL: baseURL()
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  [types.LOGIN]({ commit, dispatch }, data) {
    const _data = deepClone(data)
    return new Promise((resolve, reject) => {
      Vue.req.post('account/login', _data, {
        baseURL: baseURL()
      }).then((res) => {
        if (res.code === 1) {
          setToken(res.accessToken)
        }
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.CREATE_INFO]({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      Vue.req.post('account/register', data, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_CENTER]({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      Vue.req.get('account/center', {
        params,
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.CREATE_SALE_ORDER]({ commit, dispatch }, data) { // 创建销售订单
    return new Promise((resolve, reject) => {
      Vue.req.post('saleorder/create', data, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SALE_ORDER_LIST]({ commit, dispatch }, data) { // 获取销售订单列表
    return new Promise((resolve, reject) => {
      Vue.req.get('saleorder/list', {
        params: {
          meetingId: data.meetingId,
          pageIndex: data.pageIndex,
          pageSize: data.pageSize
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SALE_ORDER_DETAIL]({ commit, dispatch }, id) { // 获取销售订单详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`saleorder/detail/${id}`, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.CREATE_USER_RECEPTION]({ commit, dispatch }, data) { // 用户提交接机信息
    return new Promise((resolve, reject) => {
      Vue.req.post('reception/create', data, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_RECEPTION]({ commit, dispatch }, meetingId) { // 会议站点信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`reception/meeting/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SUCCESS_RECEPTION_INFO]({ commit, dispatch }, meetingId) { // 提交成功获取基础信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`reception/query/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_BOOK_LIST]({ commit, dispatch }, data) { // 获取会议通讯录信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/book/${data.meetingId}`, {
        params: {
          name: data.name
        },
        // baseURL: baseURL()
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_USER_RECEPTION]({ commit, dispatch }, meetingId) { // 获取个人接机信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`reception/userreception/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_USER_RECEPTION_LIST]({ commit, dispatch }, data) { // 获取 当前登录人 接机人员列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`reception/userreception/list/${data.meetingId}`, {
        params: {
            searchContent: data.searchContent
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_SCENICSPOT_LIST]({ commit, dispatch }, data) { // 获取会议景点
    return new Promise((resolve, reject) => {
      Vue.req.get(`scenicspot/list/${data.meetingId}`, {
        params: {
          pageIndex: data.pageIndex,
          pageSize: data.pageSize
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_SCENICSPOT_DETAIL]({ commit, dispatch }, id) { // 获取会议景点详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`scenicspot/get/${id}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_FOOD_LIST]({ commit, dispatch }, data) { // 获取会议美食
    return new Promise((resolve, reject) => {
      Vue.req.get(`food/list/${data.meetingId}`, {
        params: {
          pageIndex: data.pageIndex,
          pageSize: data.pageSize
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_FOOD_DETAIL]({ commit, dispatch }, id) { // 获取会议美食详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`food/get/${id}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_GUEST_RELATION]({ commit, dispatch }, data) { // 根据会议id 获取嘉宾列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/guest/relation/${data.meetingId}`, {
        params: {
          pageIndex: data.pageIndex,
          pageSize: data.pageSize
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_GUEST_DETAIL]({ commit, dispatch }, guestId) { // 根据嘉宾id获取详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/guest/detail/${guestId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_EXHIBITORS_LIST]({ commit, dispatch }, meetingId) { // 获取会议展商
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/exhibitors/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_EXHIBITORS_DETAIL]({ commit, dispatch }, id) { // 获取会议展商详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/exhibitors/detail/${id}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_REGISTER_CONFIGURATION]({ commit, dispatch }, meetingId) { // 获取会议注册报名配置信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/register/configuration/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // [types.GET_MEETING_SCHEDULE_LIST]({ commit, dispatch }, data) { // 获取会议日程
  //   return new Promise((resolve, reject) => {
  //     Vue.req.get(`schedule/list/${data.meetingId}`, {
  //       params: {
  //         startDate: data.startDate,
  //         endDate: data.endDate
  //       },
  //       baseURL: baseURL()
  //     }).then((res) => {
  //       resolve(res)
  //     }).catch((err) => {
  //       reject(err)
  //     })
  //   })
  // },
  [types.GET_MEETING_SCHEDULE_LIST]({ commit, dispatch }, data) { // 获取会议日程
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/${data.meetingId}/schedules`, {
        params: {
          startTime: data.startDate,
          endTime: data.endDate
        },
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // [types.GET_MEETING_SCHEDULE_DETAIL]({ commit, dispatch }, id) { // 获取会议日程详情
  //   return new Promise((resolve, reject) => {
  //     Vue.req.get(`schedule/detail/${id}`, {
  //       params: {},
  //       baseURL: baseURL()
  //     }).then((res) => {
  //       resolve(res)
  //     }).catch((err) => {
  //       reject(err)
  //     })
  //   })
  // },
  [types.GET_MEETING_SCHEDULE_DETAIL]({ commit, dispatch }, id) { // 获取会议日程详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`meetingSchedules/${id}`, {
        params: {},
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_HOTEL_LIST]({ commit, dispatch }, data) { // 获取酒店列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`hotel/list/${data.meetingId}`, {
        params: {
          pageIndex: data.pageIndex,
          pageSize: data.pageSize,
          name: data.name
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_PRODUCT_LIST]({ commit, dispatch }, data) { // 获取展品列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/exhibitors/product/${data.id}`, {
        params: {
          pageIndex: data.pageIndex,
          pageSize: data.pageSize
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_PRODUCT_DETAIL]({ commit, dispatch }, id) { // 获取会议展商详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/exhibitors/product/detail/${id}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_HOTEL_DETAIL]({ commit, dispatch }, id) { // 获取酒店详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`hotel/detail/${id}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_DININGUSER_LIST]({ commit, dispatch }, meetingId) { // 获取所有就餐人员信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/dininguser/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_EAT_STATUS]({ commit, dispatch }, meetingId) { // 获取当前登录人员就餐信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/eat/status/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_DININGUSER_USER]({ commit, dispatch }, data) { // 扫码获取就餐人员
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/dininguser/${data.meetingId}/${data.userId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPDATE_EAT_STATUS]({ commit, dispatch }, id) { // 更新就餐状态
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/update/status/${id}?t=${Date.now()}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.REGISTER_APPLY]({ commit, dispatch }, data) { // 报名会议
    return new Promise((resolve, reject) => {
      Vue.req.post('account/apply', data, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPLOAD_FILE]({ commit, dispatch }, data) { // 上传图片
    return new Promise((resolve, reject) => {
      Vue.req.post('file', data, {
        baseURL: portalURL(),
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPDATE_TOKEN]({ commit, dispatch }, meetingId) { // 根据会议ID更新token
    return new Promise((resolve, reject) => {
      Vue.req.get(`account/update/token/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        if (res.code === 1) {
          setToken(res.accessToken)
        }
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.CHECK_USERMEETING]({ commit, dispatch }, id) { // 根据选择的报名类型判断当前人是否在名单中
    return new Promise((resolve, reject) => {
      Vue.req.post(`account/check/usermeeting/current/${id}`, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPDATE_USERMEETING_ATTH]({ commit, dispatch }, data) { // 更新报名类型附件信息
    return new Promise((resolve, reject) => {
      Vue.req.post('account/update/usermeeting/atth', data, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPDATE_USERMEETING_ORDERNO]({ commit, dispatch }, data) { // 更新支付订单号和支付状态信息
    return new Promise((resolve, reject) => {
      Vue.req.post('account/update/usermeeting/orderno', data, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPDATE_USERMEETING_PAYSTATUS]({ commit, dispatch }, data) { // 更新支付状态
    return new Promise((resolve, reject) => {
      Vue.req.post('account/update/usermeeting/paystatus', data, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPDATE_USERMEETING_PAYVOUCHER]({ commit, dispatch }, data) { // 更新报名凭证
    return new Promise((resolve, reject) => {
      Vue.req.post('account/update/usermeeting/payvoucher', data, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_AUTH_TOKEN]({ commit, dispatch }, data) { // 根据设备授权码获取token
    return new Promise((resolve, reject) => {
      Vue.req.get('account/auth/token', data, {
        params: {
          authCode: data.authCode
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_USER_INFO]({ commit, dispatch }, data) { // 获取个人信息 扫描二维码
    return new Promise((resolve, reject) => {
      Vue.req.get(`account/user/info/${data.meetingId}`, {
        params: {
          userId: data.userId,
          companyId: data.companyId
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_VIDEO]({ commit, dispatch }, data) { // 获取视频列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/video/${data.meetingId}`, {
        params: {
          pageIndex: data.pageIndex,
          pageSize: data.pageSize,
          videoTime: data.videoTime
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_PICTURE]({ commit, dispatch }, data) { // 获取图片列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/picture/${data.meetingId}`, {
        params: {
          pageIndex: data.pageIndex,
          pageSize: data.pageSize,
          pictureTime: data.pictureTime
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_PICTURE_BANNER]({ commit, dispatch }, meetingId) { // 获取图片轮播图列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/picture/bannerlist/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_ORGANIZATION]({ commit, dispatch }, meetingId) { // 获取组织机构信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/organization/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_LOOKBACK_LIST]({ commit, dispatch }, data) { // 获取往期回顾列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/lookback/${data.meetingId}`, {
        params: {
          pageIndex: data.pageIndex,
          pageSize: data.pageSize
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_DESK_LIST]({ commit, dispatch }, data) { // 根据会议ID 和手机号获取 当前人就餐找桌信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/desk/list/${data.meetingId}`, {
        params: {
          phone: data.phone
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_DESK_NOTES]({ commit, dispatch }, meetingId) { // 根据会议ID 查询找桌 注意事项
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/desk/notes/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SIGN_AUTH]({ commit, dispatch }, meetingId) { // 根据会议ID 获取签到管理权限
    return new Promise((resolve, reject) => {
      Vue.req.get(`sign/manager/auth/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SIGN_LIST]({ commit, dispatch }, data) { // 根据会议ID 获取签到管理权限
    return new Promise((resolve, reject) => {
      Vue.req.get(`sign/list/${data.meetingId}/${data.type}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SIGN_PERSONNEL_LIST]({ commit, dispatch }, data) { // 根据签到地点ID 获取签到人员列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`sign/personnel/list/${data.meetingId}/${data.id}`, {
        params: {
          name: data.name,
          signStatus: data.signStatus
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SIGN_UPDATE_SIGNSTATUS]({ commit, dispatch }, data) { // 更新手机签到人员签到状态
    return new Promise((resolve, reject) => {
      Vue.req.get(`sign/update/signstatus/${data.meetingId}/${data.userId}/${data.meetingSignInId}`, {
        params: {
          signstatus: data.signstatus
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SIGN_RECEPTION_LIST]({ commit, dispatch }, meetingId) { // 根据会议ID获取当前登录人 接机 负责签到列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`sign/reception/list/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        if (res.code === 1) {
          setToken(res.accessToken)
        }
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SIGN_RECEPTION_PERSONNEL]({ commit, dispatch }, data) { // 根据站点ID和会议ID获取站点接机人员列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`sign/reception/personnel/${data.meetingId}/${data.id}`, {
        params: {
          name: data.name,
          signStatus: data.signStatus
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_SIGN_UPDATE_RECEPTION_SIGNSTATUS]({ commit, dispatch }, data) { // 根据站点ID和会议ID 更新签到人员 签到状态
    return new Promise((resolve, reject) => {
      Vue.req.get(`sign/update/reception/signstatus/${data.meetingId}/${data.userId}/${data.meetingReceptionsId}`, {
        params: {
          signstatus: data.signstatus
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.CREATE_HOTEL_ORDER]({ commit, dispatch }, data) { // 创建酒店预订订单
    return new Promise((resolve, reject) => {
      Vue.req.post('roomAssignment/individual', data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_HOTEL_ORDER_LIST]({ commit, dispatch }, data) { // 获取酒店预订订单列表
    return new Promise((resolve, reject) => {
      Vue.req.get('hotel/order/list', {
        params: {
          meetingId: data.meetingId,
          payStatus: data.payStatus,
          pageIndex: data.pageIndex,
          pageSize: data.pageSize
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.ORDER_REFUND]({ commit, dispatch }, data) { // 申请退款时 生成退款单号 返回订单id 再退款流程
    return new Promise((resolve, reject) => {
      Vue.req.get(`order/refund/${data.orderId}`, {
        params: {
          refundRemarks: data.refundRemarks
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.CREATE_USERMEETING_ORDER]({ commit, dispatch }, data) { // 创建报名预订订单
    return new Promise((resolve, reject) => {
      Vue.req.post('order/create/usermeeting', data, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.DELETE_USERMEETING_ORDER]({ commit, dispatch }, id) { // 取消报名预订订单
    return new Promise((resolve, reject) => {
      Vue.req.delete(`order/${id}`, {
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_ENROLLORDER_LIST]({ commit, dispatch }, data) { // 获取报名预订订单列表
    return new Promise((resolve, reject) => {
      Vue.req.get('order/enrollorder', {
        params: {
          meetingId: data.meetingId,
          pageIndex: data.pageIndex,
          pageSize: data.pageSize
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_RECEPTION_DETAIL]({ commit, dispatch }, meetingId) { // 查询当前登录人接站详情信息
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/user/reception/${meetingId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_USERMEETING_REFUND_DETAIL]({ commit, dispatch }, orderId) { // 根据订单id 查询报名会议 订单详情 退款订单详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`order/getusermeeting/refund/detail/${orderId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_USERMEETING_DETAIL]({ commit, dispatch }, orderId) { // 根据订单id获取会议报名订单详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`order/getusermeeting/detail/${orderId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.ORDER_DETAIL]({ commit, dispatch }, id) { // 根据酒店订单id 获取酒店订单详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`hotel/order/detail/${id}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.ORDER_REFUND_DETAIL]({ commit, dispatch }, orderId) { // 根据酒店订单id 查询退款申请
    return new Promise((resolve, reject) => {
      Vue.req.get(`hotel/order/refund/detail/${orderId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_HOTEL_ORDER_REVOKE_REFUND]({ commit, dispatch }, orderId) { // 撤销酒店退款申请
    return new Promise((resolve, reject) => {
      Vue.req.get(`hotel/order/Revoke/refund/${orderId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_ORDER_REVOKE_REFUND]({ commit, dispatch }, orderId) { // 撤销退款申请 (会议报名订单)
    return new Promise((resolve, reject) => {
      Vue.req.get(`order/revoke/refund/${orderId}`, {
        params: {},
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_HOTEL_ORDER_REFUND]({ commit, dispatch }, data) { // 酒店申请退款 申请退款时 先生成退款单号
    return new Promise((resolve, reject) => {
      Vue.req.get(`hotel/order/refund/${data.id}`, {
        params: {
            refundRemarks: data.refundRemarks
        },
        baseURL: baseURL()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.CREATE_INVOICES_HEADERS]({ commit, dispatch }, data) { // 创建发票抬头
    return new Promise((resolve, reject) => {
      Vue.req.post('invoices/headers', data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_INVOICES_HEADERS]({ commit, dispatch }, data) { // 获取发票抬头
    return new Promise((resolve, reject) => {
      Vue.req.get('invoices/headers', {
        params: {
          pageIndex: data.pageIndex,
          pageSize: data.pageSize
        },
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPDATE_INVOICES_HEADERS]({ commit, dispatch }, data) { // 修改发票抬头
    return new Promise((resolve, reject) => {
      Vue.req.put(`invoices/headers/${data.id}`, data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.DEFAULT_INVOICES_HEADERS]({ commit, dispatch }, id) { // 设置默认发票抬头
    return new Promise((resolve, reject) => {
      Vue.req.post(`invoices/headers/default/${id}`, null, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.DELETE_DEFAULT_HEADERS]({ commit, dispatch }, id) { // 取消默认发票抬头
    return new Promise((resolve, reject) => {
      Vue.req.delete(`invoices/headers/default/${id}`, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.CREATE_INVOICES]({ commit, dispatch }, data) { // 创建发票
    return new Promise((resolve, reject) => {
      Vue.req.post('invoices', data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_INVOICES]({ commit, dispatch }) { // 获取发票
    return new Promise((resolve, reject) => {
      Vue.req.get('invoices', {
        params: {},
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPDATE_INVOICES]({ commit, dispatch }, data) { // 修改发票
    return new Promise((resolve, reject) => {
      Vue.req.put(`invoices/${data.id}`, data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.CREATE_ADDRESS_BOOKS]({ commit, dispatch }, data) { // 创建地址
    return new Promise((resolve, reject) => {
      Vue.req.post('user/addressBooks', data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.GET_ADDRESS_BOOKS]({ commit, dispatch }) { // 获取地址
    return new Promise((resolve, reject) => {
      Vue.req.get('user/addressBooks', {
        params: {},
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.UPDATE_ADDRESS_BOOKS]({ commit, dispatch }, data) { // 修改地址
    return new Promise((resolve, reject) => {
      Vue.req.post(`user/addressBooks/${data.id}`, data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.DEFAULT_ADDRESS_BOOKS]({ commit, dispatch }, id) { // 设置默认地址
    return new Promise((resolve, reject) => {
      Vue.req.post(`user/addressBooks/default/${id}`, null, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.DELETE_DEFAULT_ADDRESS]({ commit, dispatch }, id) { // 取消默认地址
    return new Promise((resolve, reject) => {
      Vue.req.delete(`user/addressBooks/default/${id}`, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.MAKE_INVOICES]({ commit, dispatch }, data) { // 申请开票
    return new Promise((resolve, reject) => {
      Vue.req.post('makeInvoice', data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.MAKE_INVOICES_DETAIL]({ commit, dispatch }, id) { // 开票详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`makeInvoice/${id}`, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.WECHATPAY_PAYQUERY]({ commit, dispatch }, data) { // 微信支付查询
    return new Promise((resolve, reject) => {
      Vue.req.post('wechatspyay/payquery', data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.WECHATPAY_AUTHORIZE]({ commit, dispatch }, params) { // 微信支付获取授权
    return new Promise((resolve, reject) => {
      Vue.req.get('wechat/authorize', {
        params,
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.MEETING_HOTELS_DETAIL]({ commit, dispatch }, id) { // 获取会议酒店详情
    return new Promise((resolve, reject) => {
      Vue.req.get('/meetingHotels/' + id, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.ROOMS_ASSIGNMENT_DETAIL]({ commit, dispatch }, id) { // 分房详情
    return new Promise((resolve, reject) => {
      Vue.req.get('roomAssignment/' + id, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.MEETING_ACCESS_LOGS]({ commit, dispatch }, data) { // 访问记录
    return new Promise((resolve, reject) => {
      Vue.req.post('meetingAccessLogs/access', data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.MEETING_SHARE_LOGS]({ commit, dispatch }, data) { // 分享记录
    return new Promise((resolve, reject) => {
      Vue.req.post('meetingAccessLogs/share', data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.ASSIGNMENT_HOTEL_ROOMS]({ commit, dispatch }, data) { // 获取分房订单房间详情
    return new Promise((resolve, reject) => {
      Vue.req.get('hotelRooms', {
        baseURL: newApi(),
        params: data
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.USER_TRAVEL_DETAIL]({ commit, dispatch }, data) { // h5 出行
    return new Promise((resolve, reject) => {
      Vue.req.get('travels/user/travel', {
        baseURL: newApi(),
        params: data
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_SEAT]({ commit, dispatch }, data) { // 查询座位
    return new Promise((resolve, reject) => {
      Vue.req.get('seat', {
        baseURL: newApi(),
        params: data
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.TRAVEL_REMARK]({ commit, dispatch }, data) { // h5 出行注意事项
    return new Promise((resolve, reject) => {
      Vue.req.get('travelshtml/remark', {
        baseURL: newApi(),
        params: data
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_SEAT_AREA]({ commit, dispatch }, data) { // 获取座位区域
    return new Promise((resolve, reject) => {
      Vue.req.get('seat/area', {
        baseURL: newApi(),
        params: data
    }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.MEETING_SIGNUP_DETAIL]({ commit, dispatch }, data) { // 会议报名详情
    return new Promise((resolve, reject) => {
      Vue.req.post(`meeting/register/configuration/detail/${data}`, data, {
        baseURL: newApi()
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  [types.MEETING_PROCESS_DETAIL]({ commit, dispatch }, meetingId) { // 会议流程详情
    return new Promise((resolve, reject) => {
      Vue.req.get('meetingFlow', {
        baseURL: newApi(),
        params: {
          meetingId
        }
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_OCCUPANTS]({ commit, dispatch }, id) { // 会议入住人员列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`roomAssignment/meeting/${id}/order/occupants`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.HOTEL_CHECKIN]({ commit, dispatch }, data) { // 酒店入住
    return new Promise((resolve, reject) => {
      Vue.req.post('hotel/checkIn', data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.HOTEL_CHECKOUT]({ commit, dispatch }, data) { // 酒店离开
    return new Promise((resolve, reject) => {
      Vue.req.post('hotel/checkOut', data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_LABORS_REMIND]({ commit, dispatch }, meetingId) { // 查询注意事项
    return new Promise((resolve, reject) => {
      Vue.req.get('meetingLabors/remind', {
        baseURL: newApi(),
        params: {
          meetingId
        }
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_LABORS_INFO]({ commit, dispatch }, meetingId) { // 查询劳务信息
    return new Promise((resolve, reject) => {
      Vue.req.get('guest/meetinglabor', {
        baseURL: newApi(),
        params: {
          meetingId
        }
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.CONFIRM_LABORS]({ commit, dispatch }, data) { // 确认劳务信息
    return new Promise((resolve, reject) => {
      Vue.req.post('guest/meetinglabor', data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.NEWS_LIST]({ commit, dispatch }, data) { // 资讯列表
    return new Promise((resolve, reject) => {
      Vue.req.get('meetingNews', {
        baseURL: newApi(),
        params: data
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.NEWS_DETAIL]({ commit, dispatch }, id) { // 资讯详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`meetingNews/${id}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_COMPANY_LIST]({ commit, dispatch }, data) { // 查询用户所属公司列表
    return new Promise((resolve, reject) => {
      Vue.req.post('account/invites', data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_GUEST_SCHEDULE_DETAIL]({ commit, dispatch }, data) { // 嘉宾日程详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`meeting/${data.meetingId}/guest/detail/${data.guestId}`, {
        baseURL: baseURL()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_PRODUCT_LIST]({ commit, dispatch }, id) { // 产品列表
    return new Promise((resolve, reject) => {
      Vue.req.get(`product-intro/meeting/${id}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_PRODUCT_DETAIL]({ commit, dispatch }, id) { // 产品详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`product-intro/${id}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_VOTE_DETAIL]({ commit, dispatch }, id) { // 投票详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`votingActivities/${id}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_CONTESTANT_DETAIL]({ commit, dispatch }, params) { // 选手详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`votingActivities/${params.voteId}/Contestant/${params.contestantId}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_CONTESTANT_DETAIL]({ commit, dispatch }, params) { // 选手详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`votingActivities/${params.voteId}/Contestant/${params.contestantId}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.MAKE_VOTING]({ commit, dispatch }, data) { // 进行投票
    return new Promise((resolve, reject) => {
      Vue.req.post('votingActivities/voting', data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.JOIN_VOTING]({ commit, dispatch }, data) { // 参加投票
    return new Promise((resolve, reject) => {
      Vue.req.post('votingActivities/join', data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_LOTTERY_DETAIL]({ commit, dispatch }, id) { // 查询抽奖详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`lotteryActivities/${id}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_LOTTERY_RECORD]({ commit, dispatch }, params) { // 查询抽奖记录
    return new Promise((resolve, reject) => {
      Vue.req.get(`redeem-prizes/activity/${params.id}/${params.phone}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.DRAW_LOTTERY_ACTIVITIES]({ commit, dispatch }, data) { // 活动抽奖
    return new Promise((resolve, reject) => {
      Vue.req.post('lotteryActivities/draw', data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.JOIN_LOTTERY_ACTIVITIES]({ commit, dispatch }, data) { // 参加抽奖
    return new Promise((resolve, reject) => {
      Vue.req.post(`lotteryActivities/${data.id}/participate`, data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_ANSWER_DETAIL]({ commit, dispatch }, id) { // 查询答题抽奖详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`answerLotteries/${id}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_ANSWER_QUESTIONS]({ commit, dispatch }, params) { // 获取答题抽奖问题
    return new Promise((resolve, reject) => {
      Vue.req.get(`answerLotteries/${params.id}/questions`, {
        baseURL: newApi(),
        params
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.SUBMIT_ANSWER_QUESTIONS]({ commit, dispatch }, data) { // 提交答题结果
    return new Promise((resolve, reject) => {
      Vue.req.post(`answerLotteries/${data.lotteryId}/questions`, data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.DRAW_LOTTERY_ANSWER]({ commit, dispatch }, data) { // 进行答题抽奖
    return new Promise((resolve, reject) => {
      Vue.req.post('answerLotteries/draw', data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_QUESTIONNAIRE_LIST]({ commit, dispatch }, params) { // 获取问卷调查列表
    return new Promise((resolve, reject) => {
      Vue.req.get('questionnaires', {
        baseURL: newApi(),
        params
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_QUESTIONNAIRE_DETAIL]({ commit, dispatch }, id) { // 获取问卷调查详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`questionnaires/${id}/question`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.SUBMIT_QUESTIONNAIRE]({ commit, dispatch }, data) { // 提交问卷
    return new Promise((resolve, reject) => {
      Vue.req.post('questionnaires/answer', data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.JOIN_QUESTIONNAIRE]({ commit, dispatch }, data) { // 问卷报名
    return new Promise((resolve, reject) => {
      Vue.req.post(`questionnaires/${data.id}/participate`, data, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_EAT_NOTES]({ commit, dispatch }, meetingId) { // 获取自助餐注意事项
    return new Promise((resolve, reject) => {
      Vue.req.post(`repast/get/notes/${meetingId}`, undefined, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.UPDATE_REGISTER_INFO]({ commit, dispatch }, data) { // 会议报名信息修改
    return new Promise((resolve, reject) => {
      Vue.req.post('account/update/apply', data, {
        baseURL: baseURL()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_REGISTER_TABLE]({ commit, dispatch }, meetingId) {
    return new Promise((resolve, reject) => {
      Vue.req.post(`account/check/usermeeting/${meetingId}`, undefined, {
        baseURL: baseURL()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.AUTO_LOGIN]({ commit, dispatch }, data) { // 根据appId自动登录
    return new Promise((resolve, reject) => {
      Vue.req.post('account/autologin', data, {
        baseURL: baseURL()
      }).then(res => {
        if (res.code === 1) {
          setToken(res.accessToken)
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.OPENID_QUIT_LOGIN]({ commit, dispatch }, data) { // 根据appId退出登录
    return new Promise((resolve, reject) => {
      Vue.req.post('account/quit', data, {
        baseURL: baseURL()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_QUESTIONNAIRE_DETAIL]({ commit, dispatch }, meetingId) { // 获取会议问卷调查详情
    return new Promise((resolve, reject) => {
      Vue.req.get(`questionnaires/question/${meetingId}`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_MEETING_VOTE_DETAIL]({ commit, dispatch }, meetingId) { // 获取会议下的图片活动
    return new Promise((resolve, reject) => {
      Vue.req.get(`votingActivities/${meetingId}/info`, {
        baseURL: newApi()
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.GET_DANMU_DETAIL]({ commit, dispatch }, params) { // 获取会议下的弹幕信息
    return new Promise((resolve, reject) => {
      Vue.req.get('BarrageActivity/GetPageList', {
        baseURL: api(),
        params
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  [types.UPDATE_AVATAR]({ commit, dispatch }, data) { // 更改头像
    return new Promise((resolve, reject) => {
      Vue.req.post('account/UpdateUserAvatarUrl', data.avatarUrl, {
        baseURL: baseURL(),
        params: {
            userId: data.userId
        }
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
export default {
  actions
}
