<template>
    <div class="bulletChat-container" ref="container" :style="{ background: info.mixBanner ? `url(${info.mixBanner}) no-repeat top center / 100% 100%` : '#FFFFFF', height: `${height}px`}">
        <van-notice-bar color="#FFFFFF" background="rgba(0, 0, 0, 0.5)" class="bulletChat-notice"
        left-icon="volume-o"
        :text="info.notice"
        />
        <div class="bulletChat-content" ref="list">
            <div class="bulletChat-item animate__fadeIn" :class="{ 'is-me': item.userId === userId }" v-for="item in list" :key="item.id">
                <van-image class="user-avatar" :src="item.avatarUrl" fit="cover" round/>
                <div class="bulletChat-info">
                    <!-- <div class="user-name">{{ item.userName }}</div> -->
                    <div class="user-content">{{ item.sendContent }}</div>
                </div>
            </div>
        </div>
        <div class="bulletChat-input">
            <van-field v-model="danmu.content" placeholder="请输入弹幕内容" maxlength="30" @focus="checkLogin"/>
            <div>
                <van-button class="submit-btn" :class="{ sending: danmu.content && !sendTime }" type="primary" :color="(danmu.content && !sendTime) ? '#43c4be' : '#D9D7D8'" @touchstart="onConfirm">{{ sendSeconds > 0 ? sendSeconds + 'S' : '发送' }}</van-button>
            </div>
        </div>
    </div>
</template>
<script>
// import { signalRService, joinGame, listenGameStart, updateGameMark } from './sign.js'
import { signalRService, receiveMessage, sendMessage, closeSignalRConnection } from './sign.js'
import { mapActions } from 'vuex'
import { getUrlParam } from '@/common/myUtil'
import { getToken } from '@/common/auth'
import jwt from 'jsonwebtoken'
import moment from 'moment'

export default {
    name: 'bulletChat',
    data () {
        return {
            meetingId: getUrlParam('meetingId') || 0,
            userId: 0,
            info: {
                id: '',
                activityTitle: '',
                maxBanner: '',
                mixBanner: '',
                beginTime: '',
                endTime: '',
                notice: '',
                barrageEffect: 0
            },
            height: '100%',
            initHeight: 0,
            list: [],
            jsonArray: [],
            danmu: {
                id: '',
                userName: '',
                avatarUrl: '',
                sendContent: ''
            },
            sendSeconds: 0, // 发送间隔
            sendTime: null,
            signal: null
        }
    },
    computed: {},
    components: {
    },
    methods: {
        ...mapActions({
            getDetail: 'GET_DANMU_DETAIL'
        }),
        guid () {
            return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0
                var v = c === 'x' ? r : (r & 0x3 | 0x8)
                return v.toString(16)
            })
        },
        async loadFile () {
            try {
                const response = await fetch('/pub_banned_words.txt')
                const fileContent = await response.text()
                this.convertToJSON(fileContent)
            } catch (error) {
                console.error('Error loading file:', error)
            }
        },
        convertToJSON (fileContent) {
            const lines = fileContent.split('\n')
            const decodedLines = lines.map(line => Buffer.from(line, 'base64').toString('utf-8').replace(/\n/g, ''))
            this.jsonArray = decodedLines
        },
        filterSensitiveWords (text) {
            let filteredText = text
            this.jsonArray.forEach(word => {
                const regex = new RegExp(word, 'gi')
                filteredText = filteredText.replace(regex, '*'.repeat(word.length))
            })
            return filteredText
        },
        fetchData () {
            this.$loading.show()
            this.loadFile()
            this.getDetail({
                meetingId: this.meetingId
            }).then(res => {
                if (res) {
                    this.height = window.innerHeight
                    const element = this.$refs.list
                    this.initHeight = element.scrollHeight - element.scrollTop // 初始高度
                    Object.assign(this.info, res)
                    const token = getToken()
                    if (token) {
                        const info = jwt.decode(token)
                        this.userId = info ? Number(info.userId) : 0
                    }
                    // signalRService(`https://hxapi.huixie.wang/activityMsgHub?uid=${this.userId || 1}&aid=${this.info.id}`).then(signalR => {
                    // signalRService(`http://192.168.1.22:5265/activityMsgHub?uid=${this.userId || 1}&aid=${this.info.id}`).then(signalR => {
                    signalRService(`http://121.43.163.2:8081/activityMsgHub?uid=${this.userId || 1}&aid=${this.info.id}`).then(signalR => {
                        this.signal = signalR
                        receiveMessage(this.signal, this.handleAdd)
                        // setInterval(() => {
                        //     sendMessage(this.signal, {
                        //         aid: 1,
                        //         userId: this.userId,
                        //         message: new Date()
                        //     })
                        // }, 500)
                        // setInterval(() => {
                        //     sendMessage(this.signal, {
                        //         aid: 1,
                        //         userId: this.userId,
                        //         message: new Date()
                        //     })
                        // }, 700)
                        // setInterval(() => {
                        //     sendMessage(this.signal, {
                        //         aid: 1,
                        //         userId: this.userId,
                        //         message: new Date()
                        //     })
                        // }, 300)
                    }).catch(() => {
                        this.$toast('链接失败')
                        this.$loading.hide()
                    })
                }
                this.$loading.hide()
            }).catch(() => {
                this.$toast('获取弹幕信息失败，请重试')
                this.$loading.hide()
            })
        },
        handleAdd (data) {
            data.id = this.guid()
            this.list.unshift({ ...data })
            if (this.list.length > 100) { // 如果消息数量过多 删除最早的消息
                this.list.pop()
            }
            this.$nextTick(() => {
                const element = this.$refs.list
                if (!element) return

                const isOwnMessage = data.userId === this.userId
                const isNearBottom = (element.scrollHeight - element.scrollTop - this.initHeight) < 100

                if (isOwnMessage || isNearBottom) { // 自己发送的消息 或 当前处于滚动底部时有新消息重新滚动到底部
                    element.scrollTop = element.scrollHeight
                }
            })
        },
        onConfirm () {
            // console.log(this.signal.connection.connectionId)
            // getUserByConnId(this.signal, this.signal.connection.connectionId, (res) => {
            //     console.log(res)
            // })
            const startTime = moment(this.info.beginTime)
            const endTime = moment(this.info.endTime)
            const now = moment()
            if (now.isBefore(startTime)) {
                return this.$toast('未到弹幕发送时间')
            } else if (now.isAfter(endTime)) {
                return this.$toast('已过弹幕发送时间')
            }
            if (!this.danmu.content) {
                return this.$toast('请输入弹幕内容')
            }
            if (!this.sendTime) {
                this.sendSeconds = 5
                this.sendTime = setInterval(() => {
                    this.sendSeconds -= 1
                    if (this.sendSeconds <= 0) {
                        clearInterval(this.sendTime)
                        this.sendTime = null
                    }
                }, 1000)
                const data = {
                    aid: this.info.id,
                    userId: this.userId,
                    message: this.filterSensitiveWords(this.danmu.content)
                }
                sendMessage(this.signal, data)
                this.danmu.content = ''
            }
        },
        checkLogin () {
            if (!this.userId) {
                this.$router.push({
                    path: '/login',
                    query: {
                        meetingId: this.meetingId
                    }
                })
            }
        }
    },
    mounted () {
        this.fetchData()
    },
    beforeDestroy () {
        closeSignalRConnection(this.signal)
    }
}
</script>
<style scoped lang="scss">
.bulletChat-container {
    width: 100%;
    height: 100%;
    // padding-bottom: 0.4rem;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
    .bulletChat-notice {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }
    .bulletChat-content {
        display: flex;
        flex-direction: column-reverse;
        gap: 15px;
        padding: 15px;
        overflow-y: auto;
        max-height: calc(100vh - 68px - env(safe-area-inset-bottom));
        &::-webkit-scrollbar {
            display: none;
        }
        .bulletChat-item {
            display: flex;
            align-items: center;
            gap: 10px;
            animation-duration: 1s;
            &.is-me {
                flex-direction: row-reverse;
                .user-name {
                    text-align: right;
                }
                .bulletChat-info {
                    align-items: flex-end;
                    .user-content {
                        &::after {
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-left: 5px solid #FFFFFF;
                            border-right: 0;
                            left: unset;
                            right: -5px;
                        }
                    }
                }
            }
            .user-avatar {
                width: 38px;
                min-width: 38px;
                height: 38px;
                border-radius: 50%;
            }
            .bulletChat-info {
                display: flex;
                flex-direction: column;
                gap: 7px;
                .user-name {
                    font-weight: 500;
                    font-size: 12px;
                    color: #FFFFFF;
                }
                .user-content {
                    padding: 12px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    color: #131416;
                    position: relative;
                    width: fit-content;
                    &::after {
                        content: '';
                        width: 0;
                        height: 0;
                        border-top: 5px solid transparent;
                        border-bottom: 5px solid transparent;
                        border-right: 5px solid #FFFFFF;
                        position: absolute;
                        left: -5px;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 9;
                    }
                }
            }
        }
    }
    .bulletChat-input {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 10px 15px calc(env(safe-area-inset-bottom) + 15px);
        background: #F2F2F2;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.05);
        .submit-btn {
            width: 60px;
            height: 30px;
            padding: 0;
            // background: #D9D7D8;
            border-radius: 5px;
            & .van-button__text {
                font-size: 14px;
                color: #666666;
            }
            &.sending .van-button__text {
                font-size: 14px;
                color: #FFFFFF;
            }
        }
    }
}
</style>
