/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/routes/index'
import store from '@/store/index'
import App from '@/App.vue'
// eslint-disable-next-line no-unused-vars
import { sessionCache, localCache } from '@/common/storage'
// eslint-disable-next-line no-unused-vars
import { getToken, TOKEN_KEY, removeCookie } from '@/common/auth'
import jwtDecode from 'jsonwebtoken'
import { getUrlParam } from '@/common/myUtil'
// import { Dialog } from 'vant'
// import { Toast } from 'vant'
const routerMethods = ['push', 'replace']

routerMethods.forEach((method) => {
  const originalCall = VueRouter.prototype[method]
  VueRouter.prototype[method] = function (location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalCall.call(this, location, onResolve, onReject)
    }
    return originalCall.call(this, location).catch((err) => err)
  }
})

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

// eslint-disable-next-line no-unused-vars
const redirectLogin = (to, next) => {
  // 记录重定向地址
  const { path, query } = to
  console.log(to)
  return next({
    path: '/login',
    query: { redirect: encodeURIComponent(path), ...query }
  })
}

// 不校验token地址 白名单
const noCheckList = [
  '/',
  '/detail',
  '/meeting',
  '/404',
  '/login',
  '/information',
  '/infosuccess',
  '/infoPassword',
  '/video',
  '/image',
  '/scenicSpots',
  '/scenicspotdetail',
  '/food',
  '/fooddetail',
  '/guest',
  '/guestdetail',
  '/lookBack',
  '/exhibitors',
  '/exhibitorsdetail',
  '/schedule',
  '/scheduledetail',
  '/hierarchy',
  '/product',
  '/product-detail',
  '/vote',
  '/vote-detail',
  '/longthemegame',
  '/vote-detail',
  '/lottery-sudoku',
  '/lottery-turntable',
  '/lottery-answer',
  '/jigsaw-game',
  '/running-game',
  '/shark-game',
  '/countMoney-game',
  '/tookCoin-game',
  '/questionnaire',
  '/jump-game',
  '/process',
  '/phonebook',
  '/news',
  '/news-detail',
  '/register',
  '/hotel',
  '/hoteldetail',
  '/seat-table',
  '/eat',
  '/table',
  '/reception',
  '/saleOrder',
  '/trip',
  '/bulletChat',
  '/guestschedule'
]

// 需要登录且未报名也可以访问的地址
const noRegNeeded = [
    '/center',
    '/personal-info',
    '/register',
    '/certificate',
    '/register/payment',
    '/successful',
    '/registerOrder',
    '/registerOrder/detail',
    '/registerOrder/refund',
    '/registerOrder/refund/detail',
    '/center-list',
    '/eatadminister',
    '/checkin-administer',
    '/registration',
    '/registrationList',
    '/reception/management',
    '/edit-register'
]

// 标题白名单 (仅显示路由名称，不显示会议名)
const titleWhiteList = [
    '/questionnaire'
]

// const isProd = process.env.NODE_ENV === 'production' // 正式环境 校验白名单

let historyCount = sessionCache.get('count') * 1 || 0
sessionCache.set('/home', '0')

router.beforeEach(async (to, from, next) => {
  const toIndex = sessionCache.get(to.path)
  const fromIndex = sessionCache.get(from.path)
  if (toIndex) {
    if (
      !fromIndex ||
      parseInt(toIndex, 10) > parseInt(fromIndex, 10) ||
      (toIndex === '0' && fromIndex === '0')
    ) {
      store.commit('UPDATE_DIRECTION', { direction: 'forward' })
    } else {
      store.commit('UPDATE_DIRECTION', { direction: 'reverse' })
    }
  } else {
    ++historyCount
    sessionCache.set('count', String(historyCount))
    to.path !== '/home' && sessionCache.set(to.path, String(historyCount))
    store.commit('UPDATE_DIRECTION', { direction: 'forward' })
  }
  if (to.path === '/center') {
    store.commit('setTabValue', 1)
  } else {
    store.commit('setTabValue', 0)
  }
  document.title = titleWhiteList.includes(to.path) ? to.meta.title : localCache.get('meetingName') || to.meta.title
  const token = getToken()
  if (!token && !noCheckList.includes(to.path)) {
    redirectLogin(to, next)
    // next({
    //   path: '/404/'
    // })
  } else {
    if (token) { // 已登录 验证是否是当前会议且已报名
        const info = jwtDecode.decode(token)
        // const meetingId = getUrlParam('meetingId') || localCache.get('meetingId')
        // if (meetingId && Number(meetingId) !== Number(info.meetingId || 0)) { // 切换到其他会议清除token
        //     removeCookie(TOKEN_KEY)
        // }
        // const isNotStaffOrHotel = info.UserType * 1 !== 1 && info.UserType * 1 !== 2
        if (!noCheckList.includes(to.path) && !noRegNeeded.includes(to.path) && info.isMeetingEnroll * 1 === 1) { // 不是工作人员或酒店人员 且需要报名 走报名流程
            next()
            // if (info.payStatus * 1 === -1) { // 有账号 未报名
            //     Toast('您需要报名后才能访问该页面')
            //     next({
            //         path: '/register',
            //         query: {
            //             meetingId
            //         }
            //     })
            // } else if (info.payStatus * 1 === 1) { // 1 支付成功
            //     if (info.userStatus * 1 === 1) { // 审核通过
            //         next()
            //     } else if (info.userStatus * 1 === 0) { // 审核中
            //         Toast('报名审核中')
            //         next({
            //             path: '/meeting',
            //             query: {
            //                 meetingId
            //             }
            //         })
            //     } else { // 审核未通过
            //         Toast('报名审核未通过，请重新报名')
            //         next({
            //             path: '/register',
            //             query: {
            //                 meetingId
            //             }
            //         })
            //     }
            // } else { // 0 未支付 2 支付中 3 支付失败 4 退款中 5 退款成功 6 退款失败
            //     if (info.isMeetingPay * 1 === 1) { // 需要支付
            //         Toast('您需要报名后才能访问该页面')
            //         next({
            //             path: '/register',
            //             query: {
            //                 meetingId
            //             }
            //         })
            //     } else {
            //         next()
            //     }
            // }
        } else {
            next()
        }
    } else { // 未登录
        next()
    }
    if (Number(getUrlParam('meetingId')) !== Number(localCache.get('meetingId'))) {
        localCache.set('meetingId', getUrlParam('meetingId'))
        sessionStorage.removeItem('scheduleData') // 清除存储的日程数据
        localStorage.removeItem('navList') // 清除存储的菜单列表
    }
  }
})

router.afterEach(async (to, form) => {
  // 生产环境提示升级
  //   if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prodb' || process.env.NODE_ENV === 'alpha') {
  //     const checkVersion = await store.dispatch('checkVersion')
  //     if (!checkVersion) {
  //       window.location.reload() // 版本不同 刷新 获取最新版本
  //     }
  //   }
})
/* eslint-disable no-new */
export default {
  run() {
    new Vue({
      render(h) {
        return h(App)
      },
      store,
      router
    }).$mount('#app')
  }
}
export { router }
