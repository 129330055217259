<template>
    <div :class="{ 'user-center-main-ui': true, 'is-PC': isPC }">
        <div class="gradient-background"></div>
        <div class="user-info">
            <div class="user-avatar" @click="toInfo">
                <van-image round width="1.92rem" height="1.92rem" :src="userInfo.avatarUrl" fit="cover">
                    <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                    </template>
                    <!-- <template v-slot:error>加载失败</template> -->
                </van-image>
                <div class="user-gender" :class="{ female: userInfo.gender === 1 }" v-if="userInfo.gender !== 2"></div>
            </div>
            <div class="user-info-desc">
                <div class="user-name">
                    <div class="user-name-text">{{ userInfo.realName }}</div>
                    <i class="user-quit-icon" @click="handleQuit"></i>
                </div>
                <div class="user-phone">{{ userInfo.phoneNumber }} <span class="user-title" v-show="show">会议管理员</span></div>
            </div>
            <div class="user-code">
                <van-icon name="qr" size="40" @click="$router.push('/code' + '?meetingId=' + meetingId)"/>
            </div>
            <van-uploader v-model="fileList" :max-count="1" multiple
                :after-read="file => onAfterRead(file, 'avatarUrl')" :before-read="file => onBeforeRead(file, true)"
                :delete="() => onDelete('avatarUrl')" ref="upload" v-show="false">
            </van-uploader>
        </div>

        <div class="user-list">
            <div class="content" v-show="show">
                <div class="header" @click="onsubmit">
                    <div class="left">
                        <p class="title">管理中心</p>
                        <p>进入管理后台查看</p>
                    </div>
                    <div class="right">
                        <img :src="require('@/assets/images/gl.png')" alt="" class="image">
                    </div>
                </div>
            </div>
            <ul class="ul">
                <li v-for="(item, index) of showList" :key="index"
                    @click="$router.push(item.url + '?meetingId=' + meetingId)">
                    <img :src="item.icon" alt="">
                    <span>{{ item.name }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { getToken, TOKEN_KEY, removeCookie } from '@/common/auth'
import { getUrlParam } from '@/common/myUtil'
import jwt from 'jsonwebtoken'
// import { ImagePreview } from 'vant'
import { mapActions } from 'vuex'
import { Dialog } from 'vant'
// import wxConfig from '@/config/wxConfig'
export default {
    name: 'center',
    components: {
        [Dialog.Component.name]: Dialog.Component
    },
    data () {
        return {
            meetingId: getUrlParam('meetingId') || 0,
            srcs: '',
            key: 'huixie',
            fileList: [], // 展示上传图片
            userList: [
                {
                    icon: require('@/assets/images/icons/reg-icon.svg'),
                    name: '报名信息',
                    url: '/registerOrder',
                    hidden: false
                },
                {
                    icon: require('@/assets/images/icons/reception-icon.svg'),
                    name: '接站信息',
                    url: '/receptionsuccess',
                    hidden: true
                },
                {
                    icon: require('@/assets/images/icons/eat-icon.svg'),
                    name: '自助餐信息',
                    url: '/eat',
                    hidden: true
                },
                {
                    icon: require('@/assets/images/icons/eat-icon.svg'),
                    name: '餐桌信息',
                    url: '/table',
                    hidden: true
                },
                {
                    icon: require('@/assets/images/icons/hotel-icon.svg'),
                    name: '酒店预订信息',
                    url: '/hotelorder',
                    hidden: true
                },
                {
                    icon: require('@/assets/images/icons/hotel-icon.svg'),
                    name: '分房信息',
                    url: '/roomAllocation',
                    hidden: false
                },
                {
                    icon: require('@/assets/images/icons/saleorder-icon.svg'),
                    name: '销售订单',
                    url: '/saleorder/list',
                    hidden: true
                },
                {
                    icon: require('@/assets/images/icons/edit-register-icon.svg'),
                    name: '完善个人信息',
                    url: '/edit-register',
                    hidden: true
                }
            ],
            pathToUrlMap: { // 定义映射表 没有二级页面则不显示对应的菜单
                '/saleOrder': '/saleorder/list',
                '/reception': '/receptionsuccess',
                '/eat': '/eat',
                '/table': '/table',
                '/hotel': '/hotelorder',
                '/register': '/edit-register'
            },
            menuList: null,
            userInfo: {
                realName: '',
                phoneNumber: '',
                companyName: '',
                avatarUrl: '',
                address: '',
                post: '',
                email: '',
                gender: 0,
                callingCard: ''
            },
            form: {
                avatarUrl: ''
            },
            menuAtuh: 0,
            show: false,
            isPC: false
        }
    },
    computed: {
        showList () {
            return this.userList.filter(v => !v.hidden)
        }
    },
    methods: {
        ...mapActions({
            getUserCenter: 'GET_CENTER', // 获取个人信息
            getMeeting: 'GET_MEETING',
            quit: 'OPENID_QUIT_LOGIN',
            uploadFile: 'UPLOAD_FILE', // 图片上传接口
            updateAvatar: 'UPDATE_AVATAR', // 更换头像
            updateToken: 'UPDATE_TOKEN' // 更新token
        }),
        async onAfterRead (files, type) {
            this.$loading.show()
            files.status = 'uploading'
            const form = new FormData()
            form.append('files', files.file)

            try {
                const res = await this.uploadFile(form)
                const token = getToken()
                const info = jwt.decode(token)
                await this.updateAvatar({
                    avatarUrl: res,
                    userId: info.sub
                })
                this.userInfo[type] = res
                files.status = 'done'
            } catch (error) {
                console.error(error)
                this.$toast('上传失败')
            }
            this.$loading.hide()
        },
        onBeforeRead (file, checkSize) {
            const allowTypes = ['image/jpeg', 'image/png', 'image/jpg']
            const isTypeValid = allowTypes.includes(file.type)
            if (!isTypeValid) {
                this.$toast('只能上传JPEG,PNG,JPG格式的图片！')
                return false
            }
            const maxSize = 5 * 1024 * 1024 // 5MB
            const isSizeValid = file.size <= maxSize
            if (!isSizeValid) {
                this.$toast('图片大小不能超过5MB！')
                return false
            }
            if (checkSize) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    // const vm = this
                    reader.onload = (e) => {
                        const img = new Image()
                        img.onload = function () {
                            // 检查图片的尺寸
                            resolve()
                            // if (this.width === 590 && this.height === 826) {
                            //     resolve()
                            // } else {
                            //     vm.$toast('请上传一寸照片！')
                            //     reject(new Error('请上传一寸照片！'))
                            // }
                        }
                        img.src = e.target.result
                    }
                    reader.readAsDataURL(file)
                })
            } else {
                return true
            }
        },
        onDelete (type) {
            this.form[type] = ''
        },
        showQR () {
            // ImagePreview([this.srcs])
            this.$previewRaw([this.srcs])
        },
        toInfo () {
            // this.$router.push('/personal-info?meetingId=' + this.meetingId)
            this.$refs.upload.chooseFile()
        },
        onsubmit () {
            this.$router.push('/center-list?meetingId=' + this.meetingId)
        },
        handleQuit () {
            Dialog.confirm({
                title: '确认提示',
                message: '是否确认退出该账号？'
            })
            .then(() => {
                this.quit().then(() => {
                    // localStorage.removeItem('openid')
                    removeCookie(TOKEN_KEY)
                    localStorage.removeItem('openIdStatus')
                    this.$router.push('/meeting?meetingId=' + this.meetingId)
                    // wxConfig().then(() => {
                        // console.log('wx sdk')
                    // })
                })
            })
        },
        filterByPageType (path) {
            return this.menuList && this.menuList.has(path)
        },
        filterNav () {
            const navList = JSON.parse(window.localStorage.getItem('navList'))

            if (navList && Array.isArray(navList)) {
                this.menuList = new Set(navList.map(item => JSON.parse(item.pageType).path))

                for (const path in this.pathToUrlMap) {
                    if (this.filterByPageType(path)) {
                        const matchingItem = this.userList.find(item => item.url === this.pathToUrlMap[path])
                        if (matchingItem) {
                            matchingItem.hidden = false
                        }
                    }
                }
            } else {
                this.$loading.show()
                this.getMeeting(this.meetingId).then(res => {
                    if (res) {
                        if (res.meetingSubpages.length > 0) {
                            const navList = res.meetingSubpages.map(item => {
                                return {
                                    pageIcon: item.pageIcon,
                                    pageName: item.pageName,
                                    pageContent: item.pageContent,
                                    pageLink: item.pageLink,
                                    pageBg: item.pageBg,
                                    meetingId: this.meetingId,
                                    pageDisplayMode: item.pageDisplayMode,
                                    pageId: item.meetingSubPageId,
                                    pageType: item.pageType
                                }
                            })
                            this.menuList = new Set(navList.map(item => JSON.parse(item.pageType).path))
                            window.localStorage.setItem('navList', JSON.stringify(navList))

                            for (const path in this.pathToUrlMap) {
                                if (this.filterByPageType(path)) {
                                    const matchingItem = this.userList.find(item => item.url === this.pathToUrlMap[path])
                                    if (matchingItem) {
                                        matchingItem.hidden = false
                                    }
                                }
                            }
                        }
                    }
                }).finally(() => {
                    this.$loading.hide()
                })
            }
        }
    },
    created () {
        this.$loading.show()
        this.isPC = this.$store.state.app.isPC
        const token = getToken()
        const info = jwt.decode(token)
        if (info) {
            this.MenuAuth = Number(info.menuAtuh)
            if (this.MenuAuth === 0 && Number(info.userType) !== 2) {
                this.show = false
            } else {
                this.show = true
            }
            // if (info.UserStatus * 1 !== 1) { // 审核未通过 更新token
            //     this.updateToken(this.meetingId).then(res => {
            //     }).catch(() => {
            //         this.$toast('系统异常，联系管理员')
            //     })
            // }
            this.getUserCenter({
                id: info.sub
            }).then(res => {
                this.userInfo = Object.assign(this.userInfo, res)
                this.userInfo.avatarUrl = res.avatarUrl || ''
            }).finally(() => {
                this.$loading.hide()
            })
            this.filterNav()
        }
    }
}
</script>
<style scoped lang="scss">
.user-center-main-ui {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .gradient-background {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        width: 100%;
        max-width: 10rem;
        height: 4.3rem;
        background: linear-gradient(to bottom, #43C4BE 0%, #55CAC4 50%, #D7F2F1 100%);
    }

    .user-info {
        z-index: 2;
        height: 3.63rem;
        width: 100%;
        padding: 1.12rem 0.43rem;
        color: #fff;
        background: transparent;
        display: flex;

        .user-avatar {
            position: relative;
            width: 1.92rem;
            height: 1.92rem;

            .user-gender {
                width: 0.48rem;
                height: 0.48rem;
                background: #FFFFFF url(~@/assets/images/icons/male-icon.svg) no-repeat center center / cover;
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                right: 0;

                &.female {
                    background: #FFFFFF url(~@/assets/images/icons/female-icon.svg) no-repeat center center / cover;
                }
            }
        }

        .user-info-desc {
            flex: 1;
            height: 1.92rem;
            padding: 0.21rem 0 0 0.43rem;

            .user-name {
                font-size: 0.59rem;
                height: 0.85rem;
                line-height: 0.85rem;
                display: flex;
                align-items: center;
                .user-name-text {
                    max-width: 4rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .user-quit-icon {
                    display: inline-block;
                    width: 0.53rem;
                    height: 0.53rem;
                    margin-left: 0.32rem;
                    font-weight: 600;
                    background: url(~@/assets/images/icons/quit-icon.png) no-repeat center center / cover;
                }
            }

            .user-phone {
                font-size: 0.37rem;
                height: 0.85rem;
                line-height: 0.85rem;

                .user-title {
                    background: #3FB4FF;
                    border-radius: 3px;
                    padding: 2px;
                }
            }
        }

        .user-code {
            width: 1.6rem;
            height: 1.92rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .user-list {
        z-index: 2;
        width: 100%;
        flex: 1;
        padding-top: 0.6rem;
        box-sizing: border-box;
        background-color: rgba(249, 249, 249, 1);
        border-radius: 0.85rem 0rem 0rem 0rem;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
        }

        .content {
            margin: 0 0 30px 0;

            .header {
                margin: 0 auto;
                width: 9.14rem;
                display: flex;
                justify-content: space-between;
                padding: 15px 20px;
                background: #FFFFFF;
                box-shadow: 0 5px 5px rgba(63, 180, 252, 0.3);
                border-radius: 20px 12px 12px 12px;

                .left {
                    font-size: 12px;
                    font-weight: 500;
                    color: #999999;

                    .title {
                        font-size: 18px;
                        font-weight: 600;
                        color: #121212;
                        padding: 0 0 5px 0;
                    }
                }

                .right {
                    width: 45px;
                    height: 45px;

                    .image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .ul {

            li {
                margin: 0 auto;
                width: 9.14rem;
                height: 1.3rem;
                font-size: 0.43rem;
                color: #121212;
                position: relative;
                display: flex;
                align-items: center;

                &:after {
                    content: '';
                    width: 0.24rem;
                    height: 0.24rem;
                    transform: rotate(45deg);
                    border-top: 1px solid #a6a6a6;
                    border-right: 1px solid #a6a6a6;
                    position: absolute;
                    right: 0.1rem;
                    top: 0.54rem;
                }
            }
        }

        img {
            width: 0.53rem;
            height: 0.53rem;
            margin-right: 0.32rem;
        }
    }
}

.is-PC {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0;
    }

    .user-list {
        padding-bottom: 4rem;
    }
}
</style>
