/* eslint-disable camelcase */
/**
 * @description 登录
 * @method post【account/login】
 * @for user
 * @param {Object} Object
 * @return {Object} Data
 */
export const LOGIN = 'LOGIN'

/**
 * @description 获取会议 信息即子页面信息
 * @method get【api/v1/meeting
 * @for app
 */
export const GET_MEETING = 'GET_MEETING'
/**
 * 获取子页信息
 */
export const Get_MEETING_SUBPAGE = 'Get_MEETING_SUBPAGE'

/**
 * 获取行业
 */
export const GET_INDUSTORY = 'GET_INDUSTORY'
/**
 * 发送短信验证码
 */
export const SMS_SEND = 'SMS_SEND'
/**
 * 获取所有城市
 */
export const Get_ALLCITY = 'Get_ALLCITY'

/**
 * 创建基础信息
 */
export const CREATE_INFO = 'CREATE_INFO'

/**
 * 获取基础信息
 */
export const GET_CENTER = 'GET_CENTER'

/**
 * 创建销售订单
 */
export const CREATE_SALE_ORDER = 'CREATE_SALE_ORDER'
/**
 * 获取销售订单列表
 */
export const GET_SALE_ORDER_LIST = 'GET_SALE_ORDER_LIST'
/**
 * 获取销售订单详情
 */
export const GET_SALE_ORDER_DETAIL = 'GET_SALE_ORDER_DETAIL'
/**
 * 用户提交 接机信息
 */
export const CREATE_USER_RECEPTION = 'CREATE_USER_RECEPTION'
/**
 * 会议站点信息
 */
export const GET_RECEPTION = 'GET_RECEPTION'

/**
 * 提交成功页
 */
export const GET_SUCCESS_RECEPTION_INFO = 'GET_SUCCESS_RECEPTION_INFO'
/**
 * 获取会议通讯录信息
 */
export const GET_MEETING_BOOK_LIST = 'GET_MEETING_BOOK_LIST'
/**
 * 获取个人接机信息
 */
export const GET_USER_RECEPTION = 'GET_USER_RECEPTION'
/**
 * 获取 当前登录人 接机人员列表
 */
export const GET_USER_RECEPTION_LIST = 'GET_USER_RECEPTION_LIST'

/***
 * 获取会议景点
 */
export const GET_MEETING_SCENICSPOT_LIST = 'GET_MEETING_SCENICSPOT_LIST'
/**
 * 获取会议景点详情
 */
export const GET_MEETING_SCENICSPOT_DETAIL = 'GET_MEETING_SCENICSPOT_DETAIL'

/***
 * 获取会议美食
 */
export const GET_MEETING_FOOD_LIST = 'GET_MEETING_FOOD_LIST'
/**
 * 获取会议美食详情
 */
export const GET_MEETING_FOOD_DETAIL = 'GET_MEETING_FOOD_DETAIL'
/***
 * 获取会议展商
 */
export const GET_MEETING_EXHIBITORS_LIST = 'GET_MEETING_EXHIBITORS_LIST'
/**
 * 获取会议展商详情
 */
export const GET_MEETING_EXHIBITORS_DETAIL = 'GET_MEETING_EXHIBITORS_DETAIL'
/**
 * 根据会议id查询会议关联嘉宾列表
 */
export const GET_MEETING_GUEST_RELATION = 'GET_MEETING_GUEST_RELATION'
/**
 * 根据嘉宾id获取详情
 */
export const GET_MEETING_GUEST_DETAIL = 'GET_MEETING_GUEST_DETAIL'
/***
 * 获取会议注册报名配置信息
 */
export const GET_MEETING_REGISTER_CONFIGURATION = 'GET_MEETING_REGISTER_CONFIGURATION'
/***
 * 获取会议日程
 */
export const GET_MEETING_SCHEDULE_LIST = 'GET_MEETING_SCHEDULE_LIST'
/**
 * 获取会议日程详情
 */
export const GET_MEETING_SCHEDULE_DETAIL = 'GET_MEETING_SCHEDULE_DETAIL'
/**
 * 获取酒店列表
 */
export const GET_MEETING_HOTEL_LIST = 'GET_MEETING_HOTEL_LIST'
/**
 * 获取展品列表
 */
export const GET_MEETING_PRODUCT_LIST = 'GET_MEETING_PRODUCT_LIST'
/**
 * 获取展品详情
 */
export const GET_MEETING_PRODUCT_DETAIL = 'GET_MEETING_PRODUCT_DETAIL'
/**
 * 获取酒店详情
 */
export const GET_HOTEL_DETAIL = 'GET_HOTEL_DETAIL'
/**
 * 获取所有就餐人员信息
 */
export const GET_DININGUSER_LIST = 'GET_DININGUSER_LIST'
/**
 * 获取当前登录人员就餐信息
 */
export const GET_EAT_STATUS = 'GET_EAT_STATUS'
/**
 * 扫码获取就餐人员
 */
export const GET_DININGUSER_USER = 'GET_DININGUSER_USER'
/**
 * 更新就餐状态
 */
export const UPDATE_EAT_STATUS = 'UPDATE_EAT_STATUS'
/**
 * 报名会议
 */
export const REGISTER_APPLY = 'REGISTER_APPLY'
/**
 * 上传图片
 */
export const UPLOAD_FILE = 'UPLOAD_FILE'
/**
 * 根据会议ID更新token
 */
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
/**
 * 根据选择的报名类型判断当前人是否在名单中
 */
export const CHECK_USERMEETING = 'CHECK_USERMEETING'
/**
 * 更新报名类型附件信息
 */
export const UPDATE_USERMEETING_ATTH = 'UPDATE_USERMEETING_ATTH'
/**
 * 更新支付订单号和支付状态信息
 */
export const UPDATE_USERMEETING_ORDERNO = 'UPDATE_USERMEETING_ORDERNO'
/**
 * 更新支付状态
 */
export const UPDATE_USERMEETING_PAYSTATUS = 'UPDATE_USERMEETING_PAYSTATUS'
/**
 * 更新报名凭证
 */
export const UPDATE_USERMEETING_PAYVOUCHER = 'UPDATE_USERMEETING_PAYVOUCHER'
/**
 * 根据设备授权码获取token
 */
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN'
/**
 * 获取个人信息 扫描二维码
 */
export const GET_USER_INFO = 'GET_USER_INFO'
/**
 * 根据会议ID 获取 视频列表
 */
export const GET_MEETING_VIDEO = 'GET_MEETING_VIDEO'
/**
 * 根据会议ID 获取 图片列表
 */
export const GET_MEETING_PICTURE = 'GET_MEETING_PICTURE'
/**
 * 根据会议ID 获取 图片banner
 */
export const GET_PICTURE_BANNER = 'GET_PICTURE_BANNER'
/**
 * 根据会议ID 获取 组织机构信息
 */
export const GET_MEETING_ORGANIZATION = 'GET_MEETING_ORGANIZATION'
/**
 * 根据会议ID 获取 往期回顾列表
 */
export const GET_LOOKBACK_LIST = 'GET_LOOKBACK_LIST'
/**
 * 根据会议ID 和手机号获取 当前人就餐找桌信息
 */
export const GET_DESK_LIST = 'GET_DESK_LIST'
/**
 * 根据会议ID 查询找桌 注意事项
 */
export const GET_DESK_NOTES = 'GET_DESK_NOTES'
/**
 * 根据会议ID 获取签到管理权限
 */
export const GET_SIGN_AUTH = 'GET_SIGN_AUTH'
/**
 * 根据会议ID 获取签到列表
 */
export const GET_SIGN_LIST = 'GET_SIGN_LIST'
/**
 * 根据签到地点ID 获取签到人员列表
 */
export const GET_SIGN_PERSONNEL_LIST = 'GET_SIGN_PERSONNEL_LIST'
/**
 * 更新手机签到人员签到状态
 */
export const GET_SIGN_UPDATE_SIGNSTATUS = 'GET_SIGN_UPDATE_SIGNSTATUS'
/**
 * 根据会议ID获取当前登录人 接机 负责签到列表
 */
export const GET_SIGN_RECEPTION_LIST = 'GET_SIGN_RECEPTION_LIST'
/**
 * 根据站点ID和会议ID获取站点接机人员类别
 */
export const GET_SIGN_RECEPTION_PERSONNEL = 'GET_SIGN_RECEPTION_PERSONNEL'
/**
 * 根据站点ID和会议ID 更新签到人员 签到状态
 */
export const GET_SIGN_UPDATE_RECEPTION_SIGNSTATUS = 'GET_SIGN_UPDATE_RECEPTION_SIGNSTATUS'
/**
 * 创建酒店预订订单
 */
export const CREATE_HOTEL_ORDER = 'CREATE_HOTEL_ORDER'
/**
 * 获取酒店订单列表
 */
export const GET_HOTEL_ORDER_LIST = 'GET_HOTEL_ORDER_LIST'
/**
 * 申请退款时 生成退款单号 返回订单id 再退款流程
 */
export const ORDER_REFUND = 'ORDER_REFUND'
/**
 * 创建会议报名订单
 */
export const CREATE_USERMEETING_ORDER = 'CREATE_USERMEETING_ORDER'
/**
 * 删除会议报名订单
 */
export const DELETE_USERMEETING_ORDER = 'DELETE_USERMEETING_ORDER'
/**
 * 获取报名订单列表
 */
export const GET_ENROLLORDER_LIST = 'GET_ENROLLORDER_LIST'
/**
 * 查询当前登录人接站详情信息
 */
export const GET_RECEPTION_DETAIL = 'GET_RECEPTION_DETAIL'
/**
 * 根据订单id 查询报名会议 订单详情 退款订单详情
 */
export const GET_USERMEETING_REFUND_DETAIL = 'GET_USERMEETING_REFUND_DETAIL'
/**
 * 根据订单id获取会议报名订单详情
 */
export const GET_USERMEETING_DETAIL = 'GET_USERMEETING_DETAIL'
/**
 * 根据酒店订单id 获取酒店订单详情
 */
export const ORDER_DETAIL = 'ORDER_DETAIL'
/**
 * 根据酒店订单id 查询退款申请
 */
export const ORDER_REFUND_DETAIL = 'ORDER_REFUND_DETAIL'
/**
 * 撤销退款申请 (酒店)
 */
export const GET_HOTEL_ORDER_REVOKE_REFUND = 'GET_HOTEL_ORDER_REVOKE_REFUND'
/**
 * 撤销退款申请 (会议报名订单)
 */
export const GET_ORDER_REVOKE_REFUND = 'GET_ORDER_REVOKE_REFUND'
/**
 * 酒店申请退款  申请退款时 先生成退款单号
 */
export const GET_HOTEL_ORDER_REFUND = 'GET_HOTEL_ORDER_REFUND'
/**
 * 创建发票抬头
 */
export const CREATE_INVOICES_HEADERS = 'CREATE_INVOICES_HEADERS'
/**
 * 获取发票抬头
 */
export const GET_INVOICES_HEADERS = 'GET_INVOICES_HEADERS'
/**
 * 修改发票抬头
 */
export const UPDATE_INVOICES_HEADERS = 'UPDATE_INVOICES_HEADERS'
/**
 * 设置默认发票抬头
 */
export const DEFAULT_INVOICES_HEADERS = 'DEFAULT_INVOICES_HEADERS'
/**
 * 取消默认发票抬头
 */
export const DELETE_DEFAULT_HEADERS = 'DELETE_DEFAULT_HEADERS'
/**
 * 创建发票
 */
export const CREATE_INVOICES = 'CREATE_INVOICES'
/**
 * 获取发票
 */
export const GET_INVOICES = 'GET_INVOICES'
/**
 * 修改发票
 */
export const UPDATE_INVOICES = 'UPDATE_INVOICES'
/**
 * 创建地址
 */
export const CREATE_ADDRESS_BOOKS = 'CREATE_ADDRESS_BOOKS'
/**
 * 获取地址
 */
export const GET_ADDRESS_BOOKS = 'GET_ADDRESS_BOOKS'
/**
 * 修改地址
 */
export const UPDATE_ADDRESS_BOOKS = 'UPDATE_ADDRESS_BOOKS'
/**
 * 设置默认地址
 */
export const DEFAULT_ADDRESS_BOOKS = 'DEFAULT_ADDRESS_BOOKS'
/**
 * 取消默认地址
 */
export const DELETE_DEFAULT_ADDRESS = 'DELETE_DEFAULT_ADDRESS'
/**
 * 申请发票
 */
export const MAKE_INVOICES = 'MAKE_INVOICES'
/**
 * 发票详情
 */
export const MAKE_INVOICES_DETAIL = 'MAKE_INVOICES_DETAIL'

/**
 * 微信支付查询
 */
export const WECHATPAY_PAYQUERY = 'WECHATPAY_PAYQUERY'
/**
 * 微信支付获取授权
 */
export const WECHATPAY_AUTHORIZE = 'WECHATPAY_AUTHORIZE'
/**
 * 会议酒店详情
 */
export const MEETING_HOTELS_DETAIL = 'MEETING_HOTELS_DETAIL'
/**
 * 分房详情
 */
export const ROOMS_ASSIGNMENT_DETAIL = 'ROOMS_ASSIGNMENT_DETAIL'
/**
 * 访问记录
 */
export const MEETING_ACCESS_LOGS = 'MEETING_ACCESS_LOGS'
/**
 * 访问记录
 */
export const MEETING_SHARE_LOGS = 'MEETING_SHARE_LOGS'
/**
 * 分房订单房间详情
 */
export const ASSIGNMENT_HOTEL_ROOMS = 'ASSIGNMENT_HOTEL_ROOMS'
/**
 * 用户出行
 */
export const USER_TRAVEL_DETAIL = 'USER_TRAVEL_DETAIL'
/**
 * 获取出行注意事项
 */
export const TRAVEL_REMARK = 'TRAVEL_REMARK'
/**
 * 座位查询
 */
export const GET_SEAT = 'GET_SEAT'
/**
 * 获取座位区域
 */
export const GET_SEAT_AREA = 'GET_SEAT_AREA'
/**
 * 查询用户的报名订单2.0
 */
export const GET_USER_REGISTER_ORDERS = 'GET_USER_REGISTER_ORDERS'
/**
 * 创建报名订单2.0
 */
export const CREATE_REGISTER_ORDER = 'CREATE_REGISTER_ORDER'
/**
 * 报名订单详情2.0
 */
export const GET_REGISTER_ORDER_DETAIL = 'GET_REGISTER_ORDER_DETAIL'
/**
 * 申请支付2.0
 */
export const APPLY_ORDER_PAY = 'APPLY_ORDER_PAY'
/**
 * 取消订单2.0
 */
export const ORDER_CANCEL = 'ORDER_CANCEL'
/**
 * 删除订单2.0
 */
export const ORDER_DELETE = 'ORDER_DELETE'
/**
 * 申请退款2.0
 */
export const APPLY_REFUND = 'APPLY_REFUND'
/**
 * 取消退款申请2.0
 */
export const APPLY_REFUND_CANCEL = 'APPLY_REFUND_CANCEL'
/**
 * 酒店订单2.0
 */
export const GET_HOTEL_ORDER = 'GET_HOTEL_ORDER'
/**
 * 酒店订单详情2.0
 */
export const GET_HOTEL_ORDER_DETAIL = 'GET_HOTEL_ORDER_DETAIL'
/**
 * 分房订单2.0
 */
export const GET_ROOM_ALLOCATION = 'GET_ROOM_ALLOCATION'
/**
 * 获取会议报名详情
 */
export const MEETING_SIGNUP_DETAIL = 'MEETING_SIGNUP_DETAIL'
/**
 * 获取会议流程详情
 */
export const MEETING_PROCESS_DETAIL = 'MEETING_PROCESS_DETAIL'
/**
 * 获取会议入住人员列表
 */
export const GET_MEETING_OCCUPANTS = 'GET_MEETING_OCCUPANTS'
/**
 * 酒店入住
 */
export const HOTEL_CHECKIN = 'HOTEL_CHECKIN'
/**
 * 酒店离开
 */
export const HOTEL_CHECKOUT = 'HOTEL_CHECKOUT'
/**
 * 查询注意事项
 */
export const GET_LABORS_REMIND = 'GET_LABORS_REMIND'
/**
 * 查询劳务信息
 */
export const GET_LABORS_INFO = 'GET_LABORS_INFO'
/**
 * 劳务信息菜单隐藏
 */
export const GET_LABORS_HIDE = 'GET_LABORS_HIDE'
/**
 * 确认劳务信息
 */
export const CONFIRM_LABORS = 'CONFIRM_LABORS'
/**
 * 资讯列表
 */
export const NEWS_LIST = 'NEWS_LIST'
/**
 * 资讯详情
 */
export const NEWS_DETAIL = 'NEWS_DETAIL'
/**
 * 查询用户所属公司列表
 */
export const GET_COMPANY_LIST = 'GET_COMPANY_LIST'
/**
 * 嘉宾日程详情
 */
export const GET_GUEST_SCHEDULE_DETAIL = 'GET_GUEST_SCHEDULE_DETAIL'
/**
 * 产品列表
 */
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST'
/**
 * 产品详情
 */
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL'
/**
 * 投票详情
 */
export const GET_VOTE_DETAIL = 'GET_VOTE_DETAIL'
/**
 * 选手详情
 */
export const GET_CONTESTANT_DETAIL = 'GET_CONTESTANT_DETAIL'
/**
 * 进行投票
 */
export const MAKE_VOTING = 'MAKE_VOTING'
/**
 * 参加投票
 */
export const JOIN_VOTING = 'JOIN_VOTING'
/**
 * 查询抽奖详情
 */
export const GET_LOTTERY_DETAIL = 'GET_LOTTERY_DETAIL'
/**
 * 查询抽奖记录
 */
export const GET_LOTTERY_RECORD = 'GET_LOTTERY_RECORD'
/**
 * 抽奖
 */
export const DRAW_LOTTERY_ACTIVITIES = 'DRAW_LOTTERY_ACTIVITIES'
/**
 * 参加抽奖
 */
export const JOIN_LOTTERY_ACTIVITIES = 'JOIN_LOTTERY_ACTIVITIES'
/**
 * 加入游戏
 */
export const JOIN_GAME = 'JOIN_GAME'
/**
 * 查询答题抽奖详情
 */
export const GET_ANSWER_DETAIL = 'GET_ANSWER_DETAIL'
/**
 * 获取答题抽奖问题
 */
export const GET_ANSWER_QUESTIONS = 'GET_ANSWER_QUESTIONS'
/**
 * 提交答题结果
 */
export const SUBMIT_ANSWER_QUESTIONS = 'SUBMIT_ANSWER_QUESTIONS'
/**
 * 进行答题抽奖
 */
export const DRAW_LOTTERY_ANSWER = 'DRAW_LOTTERY_ANSWER'
/**
 * 问卷调查报名
 */
export const JOIN_QUESTIONNAIRE = 'JOIN_QUESTIONNAIRE'
/**
 * 获取问卷调查详情
 */
export const GET_QUESTIONNAIRE_DETAIL = 'GET_QUESTIONNAIRE_DETAIL'
/**
 * 提交问卷
 */
export const SUBMIT_QUESTIONNAIRE = 'SUBMIT_QUESTIONNAIRE'
/**
 * 提交游戏结果
 */
export const SUBMIT_GAME_RESULT = 'SUBMIT_GAME_RESULT'
/**
 * 获取游戏排名
 */
export const GET_GAME_RANK = 'GET_GAME_RANK'
/**
 * 获取玩家信息
 */
export const GET_PLAYER_INFO = 'GET_PLAYER_INFO'
/**
 * 获取游戏详情
 */
export const GET_GAME_INFO = 'GET_GAME_INFO'
/**
 * 获取自助餐注意事项
 */
export const GET_EAT_NOTES = 'GET_EAT_NOTES'
/**
 * 会议报名信息修改
 */
export const UPDATE_REGISTER_INFO = 'UPDATE_REGISTER_INFO'
/**
 * 获取自定义字段
 */
export const GET_REGISTER_TABLE = 'GET_REGISTER_TABLE'
/**
 * 根据appId自动登录
 */
export const AUTO_LOGIN = 'AUTO_LOGIN'
/**
 * 根据appId退出登录
 */
export const OPENID_QUIT_LOGIN = 'OPENID_QUIT_LOGIN'
/**
 * 获取问卷调查列表
 */
export const GET_QUESTIONNAIRE_LIST = 'GET_QUESTIONNAIRE_LIST'
/**
 * 获取会议下问卷调查
 */
export const GET_MEETING_QUESTIONNAIRE_DETAIL = 'GET_MEETING_QUESTIONNAIRE_DETAIL'
/**
 * 获取会议下的投票活动
 */
export const GET_MEETING_VOTE_DETAIL = 'GET_MEETING_VOTE_DETAIL'
/**
 * 获取会议下的弹幕信息
 */
export const GET_DANMU_DETAIL = 'GET_DANMU_DETAIL'
/**
 * 更改头像
 */
export const UPDATE_AVATAR = 'UPDATE_AVATAR'
