import * as signalR from '@microsoft/signalr'
import { Toast } from 'vant'
const baseUrl = 'http://47.96.120.221:8081/gameHub'

// signalR基础封装
/**
 * @param {String} url
 * @returns {Promise} 返回建立链接的signalR实例
 */
let reconnectAttempts = 0
const maxReconnectAttempts = 5

export function signalRService (url = baseUrl) {
    return new Promise((resolve, reject) => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Debug)
            .build()

        connection.onclose(async () => {
            reconnectAttempts++
            if (reconnectAttempts < maxReconnectAttempts) {
                setTimeout(() => startConnection(connection), 5000)
            } else {
                console.error('重连次数已达上限，停止重连')
                Toast('连接服务端失败')
            }
        })

        startConnection(connection).then(() => {
            resolve(connection)
        }).catch((err) => {
            reject(err)
            console.log('链接报错了', err)
        })
    })
}

async function startConnection (connection) {
    try {
        await connection.start()
        console.log('SignalR链接成功')
    } catch (err) {
        reconnectAttempts++
        console.log(`连接失败，重试中... (${reconnectAttempts}/${maxReconnectAttempts})`, err)
        if (reconnectAttempts < maxReconnectAttempts) {
            setTimeout(() => startConnection(connection), 5000)
        } else {
            console.error('重连次数已达上限，停止重连')
            Toast('连接服务端失败')
        }
    }
}

// 新增关闭连接的方法
export function closeSignalRConnection (connection) {
    if (connection) {
        connection.stop().then(() => {
            console.log('SignalR连接已关闭')
        }).catch((err) => {
            console.error('关闭连接时出错', err)
        })
    } else {
        console.log('没有可关闭的SignalR连接')
    }
}

/**
 * 接受弹幕消息
 * @param {*} connection signalR实例
 * @param {Object} info 弹幕信息
 * @param {Number} info.activityId 活动id
 * @param {String} info.avatarUrl 头像
 * @param {String} info.userName 用户昵称
 * @param {String} info.sendContent 发送内容
 * @param {Number} info.userPhone 手机号
 * @param {Function} callback 数据处理函数
 */
export function receiveMessage (connection, callback) {
    connection.on('receiveMessage', data => callback(data))
}

/**
 * 发送弹幕消息
 * @param {*} connection signalR实例
 * @param {Object} info 弹幕信息
 * @param {Number} info.aid 活动id
 * @param {Number} info.userId 用户id
 * @param {String} info.message 发送内容
 * @param {Function} callback 数据处理函数
 */
export function sendMessage (connection, info, callback) {
    const { aid, userId, message } = info
    connection.invoke('sendMessage', aid, userId, message)
}

/**
 * 查询链接用户
 * @param {*} connection signalR实例
 * @param {Object} info 弹幕信息
 * @param {Number} info.aid 活动id
 * @param {Number} info.userId 用户id
 * @param {String} info.message 发送内容
 * @param {Function} callback 数据处理函数
 */
export function getUser (connection, info, callback) {
    const { userId } = info
    connection.invoke('GetUserById', userId).then(res => {
        callback(res)
    })
}

/**
 * 查询链接用户
 * @param {*} connection signalR实例
 * @param {Object} info 弹幕信息
 * @param {Number} info.aid 活动id
 * @param {Number} info.userId 用户id
 * @param {String} info.message 发送内容
 * @param {Function} callback 数据处理函数
 */
export function getUserByConnId (connection, connId, callback) {
    connection.invoke('GetUserByConnId', connId).then(res => {
        callback(res)
    })
}
